.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  z-index: 10;
  background-color: #23293d;
}
.inactive {
  display: flex;
  z-index: 0;
  overflow: hidden;
}

@media (max-width: 2000px) {
  .active {
    height: 600px;
  }
}

@media (max-width: 1024px) {
  .active {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .active {
    height: 300px;
  }
}

@media (max-width: 640px) {
  .active {
    height: 350px;
  }
}


.margin-top{
  margin-top: 80px !important;
  margin-bottom: 40px !important;
}